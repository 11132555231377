<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <stepperGeneric :steps="steps" @complete="handleCompletion"></stepperGeneric>
  </div>
</template>

<script>
import stepperGeneric from '../../components/stepper/devis113/stepperGeneric.vue';
import stepAdresseBar113 from '../../components/stepper/devis113/stepAdresseBar113.vue';
import stepChaniterBar113 from '../../components/stepper/devis113/stepChaniterBar113.vue';
import stepClientBar113 from '../../components/stepper/devis113/stepClientBar113.vue';
import stepGeneraliteBar113 from '../../components/stepper/devis113/stepGeneraliteBar113.vue';
import stepInformationBar113 from '../../components/stepper/devis113/stepInformationBar113.vue';
import stepInstallateurBar113 from '../../components/stepper/devis113/stepInstallateurBar113.vue';
import stepReglementBar113 from '../../components/stepper/devis113/stepReglementBar113.vue';
import stepDevisBar113 from '../../components/stepper/devis113/stepDevisBar113.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    stepperGeneric,
  
  },
  data() {
    return {
      steps: [
        { label: 'Généralité', component: stepGeneraliteBar113 },
        { label: 'Client', component: stepClientBar113 },
        { label: 'Installateur', component: stepInstallateurBar113 },
        { label: 'Adresse', component: stepAdresseBar113 },
        { label: 'Information', component: stepInformationBar113 },
        { label: 'Chantier', component: stepChaniterBar113 },
        { label: 'Règlement', component: stepReglementBar113 },
        { label: 'Devis', component: stepDevisBar113 },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTabs"]),
  },
  methods: {
    handleCompletion() {
      this.$router.push('/devis/list');
    },
  },
};
</script>

  
  <style scoped>
  .inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    margin-top: 55px;
  }
  </style>